import React, { useCallback, useState } from 'react'
import Panel from '../panel/panel'
import { useTranslation } from 'react-i18next'
import CSVReader, { CSVReaderProps } from 'react-csv-reader'
import * as styles from './admin-csv-upload.module.less'
import { BulkUploadContact, bulkAddVenueAdmins } from 'src/utils/classic-api'
import Icon from '../icon/icon'
import Spinner from '../spinner/spinner'
import { StaffScope, VenueSystemRole } from 'src/utils/auth'

// We do not add venue roles of admin/superadmin for SC staff, only COMPETITION_ADMINISTRATOR
const TMT_ROLE = {
  SUPER_ADMIN: VenueSystemRole.COMPETITION_ADMINISTRATOR,
  ADMIN: VenueSystemRole.COMPETITION_ADMINISTRATOR,
  TOURNAMENT_DIRECTOR: VenueSystemRole.TOURNAMENT_DIRECTOR
}

interface ValueError {
  row: number
  error: string
}

const AdminCSVUpload: React.FC = () => {
  const { t } = useTranslation()
  const [errors, setErrors] = useState<ValueError[]>([])
  const [parseError, setParseError] = useState<string>()
  const [success, setSuccess] = useState(false)
  const [loading, setLoading] = useState(false)

  const onFileLoaded = useCallback<CSVReaderProps['onFileLoaded']>(
    async d => {
      const data = [...d]
      data.shift()
      setParseError(undefined)
      setSuccess(false)
      const valErrors: ValueError[] = []
      const admins: BulkUploadContact[] = []
      data.forEach(([ustaId, email, firstName, lastName, sectionCode, tmtRole], i) => {
        const row = i + 2
        if (!sectionCode) {
          valErrors.push({ row, error: t('contact no section_district_code') })
        } else if (!tmtRole) {
          valErrors.push({ row, error: t('contact no tmt_role') })
        } else if (!TMT_ROLE[(tmtRole as string).trim()]) {
          valErrors.push({ row, error: t('contact invalid tmt_role', { tmtRole }) })
        } else if (!ustaId && !email) {
          valErrors.push({ row, error: t('contact no id') })
        } else {
          const tmtRoleTrimmed = (tmtRole as string).trim()
          const scopes: StaffScope[] = []
          const venueRoles: ('Staff' | 'Tournament Director')[] = []

          if (tmtRoleTrimmed === 'TOURNAMENT_DIRECTOR') {
            venueRoles.push('Tournament Director')
          } else {
            venueRoles.push('Staff')
            scopes.push(StaffScope.TOURNAMENTS)
            if (tmtRoleTrimmed === 'SUPER_ADMIN') {
              scopes.push(StaffScope.SUPERADMIN)
              scopes.push(StaffScope.STAFF)
            } else if (tmtRoleTrimmed === 'ADMIN') {
              scopes.push(StaffScope.ADMIN)
            }
          }

          const contact: BulkUploadContact = {
            Role: TMT_ROLE[tmtRoleTrimmed],
            SectionCode: (sectionCode as string)?.trim(),
            FirstName: (firstName as string)?.trim(),
            LastName: (lastName as string)?.trim(),
            Scope: scopes.reduce((bits, scope) => bits | scope, 0),
            VenueRoles: venueRoles
          }
          if (ustaId) contact['ExternalId'] = (ustaId as string)?.trim()
          if (email) contact['EmailAddress'] = (email as string)?.trim()
          admins.push(contact)
        }
      })
      setErrors(valErrors)
      if (admins.length && !valErrors.length) {
        setLoading(true)
        try {
          if (await bulkAddVenueAdmins(admins)) {
            setSuccess(true)
          }
        } catch (e) {
          setParseError(t('contact parse error'))
        }
        setLoading(false)
      }
    },
    [setErrors, setParseError, setSuccess, setLoading, t]
  )

  const onError = useCallback((e: Error) => setParseError(e.message), [setParseError])

  return (
    <Panel title={t('upload admins')}>
      <CSVReader
        onFileLoaded={onFileLoaded}
        onError={onError}
        cssClass={styles.reader}
        cssInputClass={styles.readInput}
        parserOptions={{ skipEmptyLines: true }}
      />
      {parseError && <ErrorContainer>{parseError}</ErrorContainer>}
      {success && (
        <div className={styles.success}>
          <Icon name={'md-tick'} className={styles.successIcon} /> {t('contact upload success')}
        </div>
      )}
      {loading && (
        <div className={styles.loading}>
          {t('uploading contacts')}
          <Spinner />
        </div>
      )}
      <ValueErrorList errors={errors} />
    </Panel>
  )
}

interface ValueErrorListProps {
  errors: ValueError[]
}

const ValueErrorList: React.FC<ValueErrorListProps> = ({ errors }) => {
  const { t } = useTranslation()
  if (!errors.length) return null
  return (
    <ErrorContainer>
      <span>{t('contact upload error')}:</span>
      <ul>
        {errors.map(e => (
          <li key={e.row}>{t('error on row', e)}</li>
        ))}
      </ul>
    </ErrorContainer>
  )
}

const ErrorContainer: React.FC = ({ children }) => {
  return <div className={styles.error}>{children}</div>
}

export default AdminCSVUpload
