// extracted by mini-css-extract-plugin
export var bodyBase = "status-label-module--body-base---gNqy status-label-module--site-font--AhGfy";
export var bodyLarge = "status-label-module--body-large--1BHUN status-label-module--body-base---gNqy status-label-module--site-font--AhGfy";
export var bodyLargeBold = "status-label-module--body-large-bold--QNGAA status-label-module--body-base---gNqy status-label-module--site-font--AhGfy";
export var bodyRegular = "status-label-module--body-regular--8BVLP status-label-module--body-base---gNqy status-label-module--site-font--AhGfy";
export var bodyRegularBold = "status-label-module--body-regular-bold--Z2GDk status-label-module--body-base---gNqy status-label-module--site-font--AhGfy";
export var bodySmall = "status-label-module--body-small--QwsIT status-label-module--body-base---gNqy status-label-module--site-font--AhGfy";
export var bodySmallBold = "status-label-module--body-small-bold--mnnCr status-label-module--body-base---gNqy status-label-module--site-font--AhGfy";
export var borderTop = "status-label-module--border-top--kxV1z";
export var box = "status-label-module--box--fOQvU";
export var breakWordContainer = "status-label-module--break-word-container--CMzdS";
export var buttonIconBase = "status-label-module--button-icon-base--I3hgg";
export var clickLink = "status-label-module--click-link--eBRIN";
export var dropdownBase = "status-label-module--dropdown-base--ujniR";
export var dropdownSelectBase = "status-label-module--dropdown-select-base--6e2AI status-label-module--text-input--DogWP";
export var error = "status-label-module--error--MAfXt";
export var flexCol = "status-label-module--flex-col--Ps0sa";
export var formErrorMessage = "status-label-module--form-error-message--Dp1oJ";
export var h3 = "status-label-module--h3--P-FSo";
export var h4 = "status-label-module--h4--Z0Y9r";
export var hoverLink = "status-label-module--hover-link--zikzW";
export var hoverRow = "status-label-module--hover-row--CSRj4";
export var label = "status-label-module--label--ql4Cx";
export var membershipContainer = "status-label-module--membership-container--ZonQ4 status-label-module--flex-col--Ps0sa status-label-module--primary-border--OEzTw";
export var membershipHeader = "status-label-module--membership-header--20lgY";
export var membershipHeading = "status-label-module--membership-heading--K6sRO";
export var membershipLabel = "status-label-module--membership-label--f9v2e";
export var moreFiltersBorderClass = "status-label-module--more-filters-border-class--CutXR";
export var neutral = "status-label-module--neutral--ExHD5";
export var neutral2 = "status-label-module--neutral2--LSUYs";
export var pageBg = "status-label-module--page-bg--QRokr";
export var pointer = "status-label-module--pointer--gZRnP";
export var primaryBorder = "status-label-module--primary-border--OEzTw";
export var rounded = "status-label-module--rounded--TZqFv";
export var shadowBoxLight = "status-label-module--shadow-box-light--An8cM";
export var siteFont = "status-label-module--site-font--AhGfy";
export var slideDownAndFade = "status-label-module--slideDownAndFade--SF4TK";
export var slideLeftAndFade = "status-label-module--slideLeftAndFade--R41ZP";
export var slideRightAndFade = "status-label-module--slideRightAndFade--HYkZB";
export var slideUpAndFade = "status-label-module--slideUpAndFade--uwxxd";
export var statusDecoration = "status-label-module--status-decoration--v5IRE";
export var success = "status-label-module--success--g5ChZ";
export var textInput = "status-label-module--text-input--DogWP";
export var textInverted = "status-label-module--text-inverted--uKDr4";
export var textMediumDark = "status-label-module--text-medium-dark--5ueDw";
export var tooltipFont = "status-label-module--tooltipFont--dlv+J";
export var unstyledButton = "status-label-module--unstyled-button--oeWqa";
export var warning = "status-label-module--warning--+rfZn";