import React, { useMemo, useCallback, useState, useEffect } from 'react'
import { PageMaxWidth } from '../util-components/util-components'
import * as styles from './tournaments-settings.module.less'
import PaymentSettings from '../payment-settings/payment-settings'
import Panel from '../panel/panel'
import PageHeader from '../page-header/page-header'
import { useTranslation } from 'react-i18next'
import { SwitchField } from '../formik-fields/formik-fields'
import { Formik, Form } from 'formik'
import * as Yup from 'yup'
import Button from '../button/button'
import { useMutation, useQuery } from '@apollo/client'
import { UPDATE_ORG_SETTINGS, GET_ORG_SETTINGS } from './tournaments-settings-queries'
import {
  GetOrgSettings,
  GetOrgSettingsVariables,
  GetOrgSettings_organisation_settings as OrgSettings
} from 'src/graphql-types/GetOrgSettings'
import { UpdateOrgSettings, UpdateOrgSettingsVariables } from 'src/graphql-types/UpdateOrgSettings'
import { useOrgId } from 'src/apollo/local-state'
import Spinner from '../spinner/spinner'
import APIErrorMessage from '../api-error-message/api-error-message'
import FloatingNotification from '../floating-notification/floating-notification'
import AdminCSVUpload from '../admin-csv-upload/admin-csv-upload'
import { tournamentsClient } from 'src/apollo/client'
import { getClientConfig } from 'src/config/config'
import ScorecardTemplates from '../scorecard-templates/scorecard-templates'
import { StaffScope, useHasScope } from '../../utils/auth'

const TournamentSettings: React.FC = () => {
  const { t } = useTranslation()
  const orgId = useOrgId()
  const { disableTournamentSettings } = getClientConfig()
  const paymentsScope = useHasScope([StaffScope.TOURNAMENTS, StaffScope.SUPERADMIN])

  const { data, loading, error } = useQuery<GetOrgSettings, GetOrgSettingsVariables>(
    GET_ORG_SETTINGS,
    { variables: { orgId }, client: tournamentsClient }
  )

  return (
    <PageMaxWidth>
      <div className={styles.partPage}>
        <PageHeader title={t('tournament settings')} />
        {paymentsScope && <PaymentSettings />}
        {!disableTournamentSettings && (
          <TournamentSettingsPanel
            settings={data?.organisation?.settings}
            apiError={error?.message}
            loading={loading}
          />
        )}
        {data?.organisation?.hierarchy.length === 1 && <AdminCSVUpload />}
        <ScorecardTemplates />
      </div>
    </PageMaxWidth>
  )
}

interface TournamentSettingsPanelProps {
  settings?: OrgSettings | null
  apiError?: string
  loading?: boolean
}

const TournamentSettingsPanel: React.FC<TournamentSettingsPanelProps> = ({
  settings,
  apiError,
  loading
}) => {
  const { t } = useTranslation()
  const orgId = useOrgId()

  const [updateSettings, { loading: updating, error: updateError, data: updateData }] = useMutation<
    UpdateOrgSettings,
    UpdateOrgSettingsVariables
  >(UPDATE_ORG_SETTINGS, { client: tournamentsClient })

  const validationSchema = useMemo(
    () => Yup.object({ sectionalTdOverride: Yup.boolean().required() }),
    []
  )
  type FormData = Yup.InferType<typeof validationSchema>

  const initialValues = useMemo<FormData | undefined>(() => {
    if (settings) {
      const { tournamentDirectorsCanBeUsedByChildOrganisations } = settings
      return {
        sectionalTdOverride: tournamentDirectorsCanBeUsedByChildOrganisations
      }
    }
    if (!loading) return { sectionalTdOverride: false }
  }, [settings, loading])

  const [showSuccess, setShowSuccess] = useState(false)

  const onSubmit = useCallback(
    (values: FormData) => {
      setShowSuccess(false)
      updateSettings({
        variables: {
          orgId,
          settings: {
            tournamentDirectorsCanBeUsedByChildOrganisations: values.sectionalTdOverride
          }
        }
      })
    },
    [updateSettings, orgId, setShowSuccess]
  )

  useEffect(() => {
    if (updateData) setShowSuccess(true)
  }, [updateData])

  return (
    <Panel title={t('settings')}>
      {loading && <Spinner />}
      {initialValues && (
        <Formik
          validationSchema={validationSchema}
          initialValues={initialValues}
          onSubmit={onSubmit}
        >
          <Form className={styles.form}>
            <SwitchField
              horizontal={false}
              name="sectionalTdOverride"
              label={`${t('sectional td override')}:`}
            />
            <Button loading={updating} spacing={{ margins: { lg: 'top' } }} type="submit">
              {t('save')}
            </Button>
          </Form>
        </Formik>
      )}
      <APIErrorMessage error={apiError || updateError?.message} />
      {showSuccess && (
        <FloatingNotification
          icon={{ name: 'md-tick', className: styles.tick }}
          message={t('settings updated')}
          onClose={() => setShowSuccess(false)}
        />
      )}
    </Panel>
  )
}

export default TournamentSettings
