import React, { useMemo, useState } from 'react'
import Panel from '../panel/panel'
import { useTranslation } from 'react-i18next'

import { useOrgId, useOrgName } from 'src/apollo/local-state'
import { BodyLarge } from '../typography/typography'
import Button from '../button/button'

import * as styles from './scorecard-template.module.less'
import { Form, Formik } from 'formik'
import { TextField } from '../formik-fields/formik-fields'
import * as Yup from 'yup'
import { Link, navigate } from 'gatsby'
import { OrgConfig, OrgConfigVariables } from '../../graphql-types/OrgConfig'
import Spinner from '../spinner/spinner'
import { useMutation, useQuery } from '@apollo/client'
import { GET_ORG_CONFIG, SET_ORG_CONFIG } from './scorecard-queries'
import { meshGatewayClient } from '../../apollo/client'
import ButtonModal from '../modal/modal'
import { SetOrgConfig, SetOrgConfigVariables } from '../../graphql-types/SetOrgConfig'

const ScorecardTemplates: React.FC = () => {
  const { t } = useTranslation()
  const [addNew, setAddNew] = useState(false)
  const [removeScorecard, setRemoveScorecard] = useState(false)
  const [selectedScorecard, setSelectedScorecard] = useState('')
  const facility = useOrgName()
  const orgId = useOrgId()

  const { data: orgConfig, loading } = useQuery<OrgConfig, OrgConfigVariables>(GET_ORG_CONFIG, {
    client: meshGatewayClient,
    variables: {
      orgId: orgId?.toUpperCase()
    }
  })

  const [saveOrgConfig, { loading: saveOrgLoading }] = useMutation<
    SetOrgConfig,
    SetOrgConfigVariables
  >(SET_ORG_CONFIG, {
    client: meshGatewayClient
  })

  const validationSchema = useMemo(() => {
    return Yup.object({ name: Yup.string().required(t('enter template name')) })
  }, [t])

  const handleCreateTemplate = values => {
    navigate('scorecard-templates/create-template', { state: { templateName: values.name } })
  }

  const handleOpenRemoveScorecard = (templateId: string) => {
    setRemoveScorecard(true)
    setSelectedScorecard(templateId)
  }

  const handleRemove = () => {
    const currentScorecard = orgConfig?.orgConfig?.hierarchy?.find(
      org => org.organisationId === orgId?.toUpperCase()
    )
    const templates = currentScorecard?.tieFormatTemplates?.slice() || []
    const selectedTemplateIndex = templates.findIndex(obj => obj.templateId === selectedScorecard)

    if (selectedTemplateIndex !== -1) {
      templates.splice(selectedTemplateIndex, 1)
      saveOrgConfig({
        variables: {
          orgId: orgId.toUpperCase(),
          config: {
            tieFormatTemplates: templates
          }
        }
      }).then(() => {
        setRemoveScorecard(false)
      })
    }
  }

  if (loading) {
    return (
      <Panel title={t('scorecard templates')} testId="loading-config">
        <Spinner />
      </Panel>
    )
  }

  return (
    <Panel title={t('scorecard templates')}>
      {!addNew ? (
        <>
          <BodyLarge spacing={{ margins: { sm: 'vertical' } }}>
            {t('configure scorecard')}
          </BodyLarge>
          {orgConfig?.orgConfig?.hierarchy?.map(org => {
            return org?.tieFormatTemplates?.map(template => {
              return (
                <div className={styles.templateList} key={template.templateId}>
                  <p>{template.templateName}</p>
                  <div className={styles.templateActions}>
                    {orgId?.toUpperCase() === org.organisationId ? (
                      <>
                        <Link
                          to={`scorecard-templates/edit/${template.templateId}`}
                          className={styles.editLink}
                        >
                          {t('edit')}
                        </Link>
                        <Button
                          className={styles.deleteButton}
                          level="link"
                          onClick={() => handleOpenRemoveScorecard(template.templateId)}
                        >
                          {t('delete')}
                        </Button>
                      </>
                    ) : (
                      <Link
                        className={styles.editLink}
                        to={`scorecard-templates/edit/${template.templateId}`}
                      >
                        {t('view')}
                      </Link>
                    )}
                  </div>
                </div>
              )
            })
          })}
          <div className={styles.addScorecardButton}>
            <Button spacing={{ margins: { sm: 'right' } }} onClick={() => setAddNew(true)}>
              {t('add scorecard template')}
            </Button>
          </div>
        </>
      ) : (
        <Formik
          validationSchema={validationSchema}
          onSubmit={handleCreateTemplate}
          initialValues={{ name: '' }}
        >
          {() => {
            return (
              <Form className={styles.addContact}>
                <TextField
                  size="small"
                  name="name"
                  label={t('scorecard template name') + '*'}
                  placeholder={t('scorecard name placeholder', { facility })}
                />
                <div className={styles.addScorecardButton}>
                  <Button spacing={{ margins: { sm: ['top', 'right'] } }} type="submit">
                    {t('continue')}
                  </Button>
                  <Button
                    level="secondary"
                    spacing={{ margins: { sm: ['top', 'right'] } }}
                    onClick={() => setAddNew(false)}
                  >
                    {t('cancel')}
                  </Button>
                </div>
              </Form>
            )
          }}
        </Formik>
      )}
      <ButtonModal
        show={removeScorecard}
        openButton={{ hidden: true }}
        title={t('delete default scorecard')}
        onVisibilityChange={setRemoveScorecard}
        actionButtons={[
          {
            id: 'cancel',
            content: t('cancel'),
            props: {
              level: 'secondary',
              onClick: () => setRemoveScorecard(false)
            }
          },
          {
            id: 'continue',
            content: t('continue'),
            props: {
              type: 'submit',
              level: 'warning',
              onClick: handleRemove,
              loading: saveOrgLoading
            }
          }
        ]}
      >
        {t('are you sure you want to delete scorecard')}
      </ButtonModal>
    </Panel>
  )
}

export default ScorecardTemplates
