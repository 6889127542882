import React from 'react'
import * as styles from './status-label.module.less'
import cx from 'classnames'
import { useSpacing, SpacingProps } from 'src/hooks/spacing'

export type LabelVariety = 'success' | 'error' | 'warning' | 'neutral' | 'neutral2'

interface Props extends SpacingProps {
  corners?: 'box' | 'rounded'
  variety?: LabelVariety
}

const StatusLabel: React.FC<Props> = ({
  children,
  corners = 'box',
  variety = 'neutral',
  spacing
}) => {
  const spacingClass = useSpacing(spacing)
  return (
    <span className={cx(styles.label, styles[corners], styles[variety], spacingClass)}>
      {children}
    </span>
  )
}

export default StatusLabel
