// extracted by mini-css-extract-plugin
export var bodyBase = "tournaments-settings-module--body-base--+0yU9 tournaments-settings-module--site-font--CcKPJ";
export var bodyLarge = "tournaments-settings-module--body-large--YyIwA tournaments-settings-module--body-base--+0yU9 tournaments-settings-module--site-font--CcKPJ";
export var bodyLargeBold = "tournaments-settings-module--body-large-bold--RBayF tournaments-settings-module--body-base--+0yU9 tournaments-settings-module--site-font--CcKPJ";
export var bodyRegular = "tournaments-settings-module--body-regular--g7oUx tournaments-settings-module--body-base--+0yU9 tournaments-settings-module--site-font--CcKPJ";
export var bodyRegularBold = "tournaments-settings-module--body-regular-bold--ixrvy tournaments-settings-module--body-base--+0yU9 tournaments-settings-module--site-font--CcKPJ";
export var bodySmall = "tournaments-settings-module--body-small--jeLQa tournaments-settings-module--body-base--+0yU9 tournaments-settings-module--site-font--CcKPJ";
export var bodySmallBold = "tournaments-settings-module--body-small-bold--Fk1Y4 tournaments-settings-module--body-base--+0yU9 tournaments-settings-module--site-font--CcKPJ";
export var borderTop = "tournaments-settings-module--border-top--tQP7B";
export var breakWordContainer = "tournaments-settings-module--break-word-container--tFU3q";
export var buttonIconBase = "tournaments-settings-module--button-icon-base--Qh9wY";
export var clickLink = "tournaments-settings-module--click-link--f1KUN";
export var dropdownBase = "tournaments-settings-module--dropdown-base--6QurO";
export var dropdownSelectBase = "tournaments-settings-module--dropdown-select-base--MYvV0 tournaments-settings-module--text-input--rrR1E";
export var flexCol = "tournaments-settings-module--flex-col--CH1jO";
export var form = "tournaments-settings-module--form--JR2cW";
export var formErrorMessage = "tournaments-settings-module--form-error-message--CyWSM";
export var h3 = "tournaments-settings-module--h3--2DeZp";
export var h4 = "tournaments-settings-module--h4--NxyRb";
export var hoverLink = "tournaments-settings-module--hover-link--2TwGI";
export var hoverRow = "tournaments-settings-module--hover-row--NXkx7";
export var membershipContainer = "tournaments-settings-module--membership-container--yeUY- tournaments-settings-module--flex-col--CH1jO tournaments-settings-module--primary-border--9iSQc";
export var membershipHeader = "tournaments-settings-module--membership-header--JCjMJ";
export var membershipHeading = "tournaments-settings-module--membership-heading--35Gf7";
export var membershipLabel = "tournaments-settings-module--membership-label--nIOoC";
export var moreFiltersBorderClass = "tournaments-settings-module--more-filters-border-class--s9mER";
export var pageBg = "tournaments-settings-module--page-bg--7Ju3E";
export var partPage = "tournaments-settings-module--part-page--ScBir";
export var pointer = "tournaments-settings-module--pointer--nq-u6";
export var primaryBorder = "tournaments-settings-module--primary-border--9iSQc";
export var shadowBoxLight = "tournaments-settings-module--shadow-box-light--5Ang-";
export var siteFont = "tournaments-settings-module--site-font--CcKPJ";
export var slideDownAndFade = "tournaments-settings-module--slideDownAndFade--BrSmN";
export var slideLeftAndFade = "tournaments-settings-module--slideLeftAndFade--WeX43";
export var slideRightAndFade = "tournaments-settings-module--slideRightAndFade--cW0gV";
export var slideUpAndFade = "tournaments-settings-module--slideUpAndFade---PhX8";
export var statusDecoration = "tournaments-settings-module--status-decoration--E7D+m";
export var textInput = "tournaments-settings-module--text-input--rrR1E";
export var textInverted = "tournaments-settings-module--text-inverted--3LndK";
export var textMediumDark = "tournaments-settings-module--text-medium-dark--WP8qd";
export var tick = "tournaments-settings-module--tick--klThB";
export var tooltipFont = "tournaments-settings-module--tooltipFont--9c4gn";
export var unstyledButton = "tournaments-settings-module--unstyled-button--W95lX";