import React, { useMemo, useCallback, useEffect, ReactNode, useState } from 'react'
import Panel from '../panel/panel'
import { useTranslation } from 'react-i18next'
import { useQuery, useMutation, useLazyQuery } from '@apollo/client'
import {
  GET_DEFAULT_ACCOUNT,
  GENERATE_NEW_ACCOUNT_URL,
  GET_ORG_URL_SEGMENT,
  GET_ACCOUNT_LOGIN_LINK
} from './payment-settings-queries'
import {
  GetDefaultAccountVariables,
  GetDefaultAccount,
  GetDefaultAccount_venueDefaultAccount as Account,
  GetDefaultAccount_venueDefaultAccount_status as AccountStatusType
} from 'src/graphql-types/GetDefaultAccount'
import { useOrgId, useOrgName } from 'src/apollo/local-state'
import { paymentClient, tournamentsClient } from 'src/apollo/client'
import Spinner from '../spinner/spinner'
import Icon from '../icon/icon'
import * as styles from './payment-settings.module.less'
import moment from 'moment'
import Button from '../button/button'
import { BodyLarge, BodyRegular } from '../typography/typography'
import {
  GenerateNewAccountUrl,
  GenerateNewAccountUrlVariables
} from 'src/graphql-types/GenerateNewAccountUrl'
import { retrieveUser } from 'src/utils/storage/local-storage'
import { GetOrgUrlSegment, GetOrgUrlSegmentVariables } from 'src/graphql-types/GetOrgUrlSegment'
import APIErrorMessage from '../api-error-message/api-error-message'
import {
  GetDefaultAccountLoginLink,
  GetDefaultAccountLoginLinkVariables
} from 'src/graphql-types/GetDefaultAccountLoginLink'
import RadioButtonGroup from '../radio-button-group/radio-button-group'
import { getClientConfig, getEnvConfig } from 'src/config/config'
import StatusLabel from '../status-label/status-label'
import Alert from '../alert/alert'

const PaymentSettings: React.FC = () => {
  const { t } = useTranslation()
  const orgId = useOrgId()
  const facility = useOrgName()

  const { data, loading } = useQuery<GetDefaultAccount, GetDefaultAccountVariables>(
    GET_DEFAULT_ACCOUNT,
    {
      client: paymentClient,
      variables: { orgId }
    }
  )
  const account = data?.venueDefaultAccount
  const accountIsMine = useMemo(() => retrieveUser()?.ID === data?.venueDefaultAccount?.userId, [
    data
  ])

  const [changingAccount, setChangingAccount] = useState(false)

  const [getLoginLink, { data: loginLinkData, loading: loadingLoginLink }] = useLazyQuery<
    GetDefaultAccountLoginLink,
    GetDefaultAccountLoginLinkVariables
  >(GET_ACCOUNT_LOGIN_LINK, { client: paymentClient })

  const onActionButtonClick = useCallback(() => {
    if (accountIsMine) {
      getLoginLink({ variables: { orgId } })
    } else {
      setChangingAccount(!changingAccount)
    }
  }, [accountIsMine, getLoginLink, orgId, setChangingAccount, changingAccount])

  useEffect(() => {
    if (loginLinkData?.venueDefaultAccount?.loginLink && typeof window !== 'undefined') {
      window.location.href = loginLinkData.venueDefaultAccount?.loginLink
    }
  }, [loginLinkData])

  const userAccountRestricted = useMemo(() => {
    const { chargesEnabled, payoutsEnabled } = account?.status ?? {}
    const approved = chargesEnabled && payoutsEnabled
    return accountIsMine && !approved
  }, [accountIsMine, account])

  const actionButtonLoading = loadingLoginLink

  return (
    <Panel
      title={t('connected account')}
      headerEndContent={
        account && (
          <Button
              level={changingAccount ? 'secondary' : 'primary'}
              loading={actionButtonLoading}
              onClick={onActionButtonClick}
              size={'sm'}
              spacing={{ margins: { sm: 'left' } }}
            >
              {t(accountIsMine ? 'edit account' : changingAccount ? 'cancel' : 'change')}
            </Button>
        )
      }
    >
      {userAccountRestricted && (
        <Alert variant={'error'} spacing={{ margins: { md: 'vertical' } }}>
          {t('connected account restricted')}
        </Alert>
      )}
      <div className={styles.connectedAccount}>
        <Icon name={'xl-payment'} />
        <div className={styles.connectedAccountContent}>
          {loading && <Spinner />}
          {!loading &&
            (account && !changingAccount ? (
              <ConnectedAccountInfo account={account} accountIsMine={accountIsMine} />
            ) : (
              <SetUpAccount firstTime={!changingAccount} />
            ))}
        </div>
      </div>
      <BodyRegular light spacing={{ margins: { lg: 'top' } }}>
        {t('connected account info', { facility })}
      </BodyRegular>
    </Panel>
  )
}

interface ConnectedAccountProps {
  account: Account
  accountIsMine: boolean
}

const ConnectedAccountInfo: React.FC<ConnectedAccountProps> = ({
  account: { firstName, lastName, email, created, status },
  accountIsMine
}) => {
  const { t } = useTranslation()

  return (
    <>
      <AccountInfoItem
        label={t('account owner')}
        value={
          <>
            {firstName} {lastName}{' '}
            {accountIsMine && <span className={styles.you}> ({t('you')})</span>}
          </>
        }
      />
      <AccountInfoItem label={t('email')} value={email} />
      <AccountInfoItem
        label={t('date created')}
        value={t('date created payment', { date: moment.unix(created) })}
      />
      <AccountInfoItem label={t('status')} value={<AccountStatus status={status} />} />
    </>
  )
}

interface AccountInfoItemProps {
  label: ReactNode
  value: ReactNode | null
}

const AccountInfoItem: React.FC<AccountInfoItemProps> = ({ label, value }) => {
  const { t } = useTranslation()
  return (
    <div className={styles.accountInfo}>
      <span className={styles.accountInfoLabel}>{label}:</span> {value || t('n/a')}
    </div>
  )
}

interface AccountStatusProps {
  status: AccountStatusType
}

const AccountStatus: React.FC<AccountStatusProps> = ({ status }) => {
  const { t } = useTranslation()
  const approved = status.chargesEnabled && status.payoutsEnabled
  return (
    <StatusLabel variety={approved ? 'success' : 'error'}>
      {approved ? t('approved') : t('restricted')}
    </StatusLabel>
  )
}

interface SetUpAccountProps {
  firstTime: boolean
}

const SetUpAccount: React.FC<SetUpAccountProps> = ({ firstTime }) => {
  const { t } = useTranslation()
  const orgId = useOrgId()
  const facility = useOrgName()

  const [
    getUrlSegment,
    { data: orgData, loading: loadingOrgData, error: orgDataError }
  ] = useLazyQuery<GetOrgUrlSegment, GetOrgUrlSegmentVariables>(GET_ORG_URL_SEGMENT, {
    client: tournamentsClient,
    variables: { orgId }
  })

  const [accountTypeStep, setAccounntTypeStep] = useState(!firstTime)
  const [selectedAccountType, setSelectedAccountType] = useState('individual')

  const [generateNewAccountUrl, { data, loading, error }] = useMutation<
    GenerateNewAccountUrl,
    GenerateNewAccountUrlVariables
  >(GENERATE_NEW_ACCOUNT_URL, { client: paymentClient })

  const userInfo = useMemo(retrieveUser, [])

  useEffect(() => {
    // This will only be triggered once the lazy query to get org data is triggered
    if (orgData?.organisation?.urlSegment && userInfo) {
      const {
        ID: id,
        FirstName: firstName,
        LastName: lastName,
        EmailAddress: email,
        PhoneNumber: phone
      } = userInfo

      generateNewAccountUrl({
        variables: {
          accountIntegration: {
            type: selectedAccountType,
            userId: id,
            venueId: orgId,
            description: `${facility} default account`,
            email,
            firstName,
            lastName,
            phone,
            setVenueDefault: true,
            country: getClientConfig().stripeCountry,
            redirectUrl: getEnvConfig().PAYMENT_CLASSIC_REDIRECT_URL,
            additionalStateSegments: [
              `${getEnvConfig().CLUBSPARK_CLASSIC_ADMIN_SITE}/${orgData.organisation.urlSegment}`, // classic venue url
              `${getEnvConfig().PAYMENT_CC_REDIRECT_URL}` // final redirect url back here
            ]
          }
        }
      })
    }
  }, [orgData, generateNewAccountUrl, userInfo, selectedAccountType])

  const setUpAccount = useCallback(() => {
    getUrlSegment() // This kicks off the above useEffect to generate the stripe url
  }, [getUrlSegment, selectedAccountType])

  useEffect(() => {
    // once we've generated the stripe url, trigger the redirect
    if (data?.integrationUrl && typeof window !== 'undefined') {
      window.location.href = data.integrationUrl
    }
  }, [data])

  return (
    <>
      {firstTime && (
        <BodyLarge spacing={{ margins: { sm: 'vertical' } }}>
          {t('no connected account', { facility })}
        </BodyLarge>
      )}
      <div className={styles.setupButtonContainer}>
        {!accountTypeStep ? (
          <>
            <Button
              spacing={{ margins: { sm: 'right' } }}
              loading={loading || loadingOrgData}
              onClick={() => setAccounntTypeStep(true)}
            >
              {t('set up account')}
            </Button>
            {/* <BodyRegular>{t('stripe redirect')}</BodyRegular> */}
            <APIErrorMessage error={orgDataError?.message ?? error?.message} />
          </>
        ) : (
          <>
            <BodyRegular>{t('select account type')}</BodyRegular>
            <RadioButtonGroup
              value={selectedAccountType}
              handleChange={e => setSelectedAccountType(e.target.value)}
              options={[
                { value: 'individual', label: t('individual') },
                { value: 'company', label: t('company') }
              ]}
            />
            <Button
              spacing={{ margins: { sm: ['right', 'top'] } }}
              loading={loading || loadingOrgData}
              onClick={setUpAccount}
            >
              {t('stripe redirect')}
            </Button>
          </>
        )}
      </div>
    </>
  )
}

export default PaymentSettings
